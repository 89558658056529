import _ from 'lodash';
import moment from 'moment';

export const gradientColours: string[] = [
  '#99aaff',
  '#a6b5ff',
  '#b2bfff',
  '#bfcaff',
  '#ccd5ff',
  '#d8dfff',
];

export function getFormattedTimeInfo(startAt: Date, endAt: Date, timeSun?: any): string {
  const obj = new Intl.DateTimeFormat('th-TH', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'Asia/Bangkok',
  });

  const isDefaultDateTime = moment(endAt).format('YYYY-MM-DD') == '2099-12-31';

  let timeLabel = 'ตลอดเวลา';
  if (timeSun && !_.isEqual([[0, 86399]], timeSun)) {
    timeLabel = `${moment()
      .startOf('day')
      .seconds(timeSun[0][0])
      .format('HH:mm')}-${moment()
      .startOf('day')
      .seconds(timeSun[0][1])
      .format('HH:mm')}`;
  }

  if (isDefaultDateTime) return `ทุกวัน / ${timeLabel}`;
  return `${obj.format(startAt)} - ${obj.format(endAt)} / ${timeLabel}`;
}

// See https://stackoverflow.com/a/12646864/10626911
export function getShuffledArray(_array: any[]): any[] {
  let array = [..._array];
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}
